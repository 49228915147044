import Api from '@/fw-modules/fw-core-vue/api/Api'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlAi
    return api
  },
  translate(sourceLanguage, targetLanguage, text) {
    return this.base().post('/v1/translate', {
      source_language: sourceLanguage,
      target_language: targetLanguage,
      message: text,
    })
  },
}
